<template>
	<van-tabbar v-if="show" v-model="active" style="z-index:10" active-color="#7e5678" :border="true" inactive-color="#979799">
		<van-tabbar-item v-for="(k, key) in item" :key="key" :to="key !== 2 && key !== 3 ? k.router : ''" @click="key === 2 ? openTikTok() : key === 3 ? beforeWithdraw() : null">
			<span>{{ k.title }}</span>
			<template #icon="props">
				<img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" v-show="key !== 2" />
				<img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" class="tui" style="height: 4rem;" v-show="key === 2" />
			</template>
		</van-tabbar-item>
	</van-tabbar>
</template>

<script>
export default {
	data() {
		return {
			show: false,
			active: 0,
			item: [
				{
					router: '/Home',
					title: "",
					icon: {
						active: '/img/footer/首页选中.jpg',
						noactive: '/img/footer/首页未选中.jpg'
					}
				},
				{
					router: '/GameRecord',
					title: "",
					icon: {
						active: '/img/footer/预约选中.jpg',
						noactive: '/img/footer/预约未选中.jpg'
					}
				},
				{
					router: '/Game',
					title: '',
					icon: {
						active: '/img/footer/beauty.52660ad1.png',
						noactive: '/img/footer/beauty.52660ad1.png'
					}
				},
				{
					router: '/withdraw',
					title: "",
					icon: {
						active: '/img/footer/tikuanjihuo.png',
						noactive: '/img/footer/tikuanwjh.png'
					}
				},
				{
					router: '/Mine',
					title: "",
					icon: {
						active: '/img/footer/我的选中.jpg',
						noactive: '/img/footer/我的未选中.jpg'
					}
				}
			]
		};
	},
	methods: {
    getChooseItem(){
      this.$http({
        method: 'get',
        url: 'base_choose'
      }).then(res=>{
          if(res.code == 200){
            this.item[2].icon = res.data;
          }
      })
    },
    texts(){
      this.item[0].title = this.$t("footer.home");
      this.item[1].title = this.$t("mine.record");
      this.item[3].title = this.$t("mine.withdraw");
      this.item[4].title = this.$t("footer.mine");
    },
    openTikTok() {
      window.open("https://www.tiktok.com", "_blank");
    },
    beforeWithdraw() {
		// 判断用户是否已绑定银行卡
		this.$http({
				method: 'get',
				url: 'user_get_bank',
		}).then(res => {
				if(res.data.is_bank){
					this.$router.push('/withdraw');
				} else {
					this.$router.push('/Setbank');
					this.$toast(this.$t("msg.请设置收款卡!"));
				}
		});
		}
  },
	watch: {
		$route(to) {
			if (to.name == 'home') {
				this.active = 0;
				this.show = true;
			} else if (to.name == 'GameRecord') {
				this.active = 1;
				this.show = true;
			} else if (to.name == 'game') {
				this.active = 2;
				this.show = true;
			} else if (to.name == 'withdraw') {
				this.active = 3;
				this.show = true;
			} else if (to.name == 'mine') {
				this.active = 4;
				this.show = true;
			} else {
				this.show = false;
			}
      this.texts();
		}
	},
	created() {
    this.getChooseItem();//获取首页游戏列表
    this.texts();
		if (this.$route.name == 'home') {
			this.active = 0;
			this.show = true;
		} else if (this.$route.name == 'GameRecord') {
			this.active = 1;
			this.show = true;
		} else if (this.$route.name == 'game') {
			this.active = 2;
			this.show = true;
		} else if (this.$route.name == 'withdraw') {
			this.active = 3;
			this.show = true;
		} else if (this.$route.name == 'mine') {
			this.active = 4;
			this.show = true;
		} else {
			this.show = false;
		}
	}
};
</script>

<style lang="less" scoped>
@tabbar-height: 110px;
@tabbar-img: 75px;
.van-tabbar {
	height: @tabbar-height;
}
.van-tabbar-item__icon img {
	height: @tabbar-img;
}
.van-tabbar-item {
	font-size: 26px;
}
.tui {
	width: 4rem;
	margin-top: -10.333vw;
	background-color: white;
	border-radius: 50%;
	border: 10px solid white;
	z-index: 10;
}
[class*='van-hairline']::after {
	border: none !important;
}
</style>
